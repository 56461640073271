import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import { Col, ProgressBar, Row } from 'DesignSystem'

import { registerCompany } from 'Store/Actions/Company'

function Charging ({ setSizeDialog, dataContentManager, setShowFooter, prev, next, setData }: any) {
  const [progress, setProgress] = useState(0)
  const intl = useIntl()

  const handleFinishCompanyCreate = async () => {
    try {
      if (!(
        dataContentManager.name &&
        dataContentManager.slug &&
        dataContentManager.icon &&
        dataContentManager.color &&
        dataContentManager.timezone &&
        dataContentManager.locale &&
        dataContentManager.currency_read
      )) {
        return
      }

      const response = await registerCompany({
        name: dataContentManager.name,
        slug: dataContentManager.slug,
        icon: dataContentManager.icon,
        color: dataContentManager.color,
        timezone: dataContentManager.timezone,
        locale: dataContentManager.locale,
        currency_write: dataContentManager.currency_read,
        currency_read: dataContentManager.currency_read
      })

      setData({
        id: response.id
      })
      next()
    } catch (e: any) {
      prev()
    }
  }

  useEffect(() => {
    setSizeDialog('lg')
    setShowFooter(false)

    const timer = setInterval(() => {
      setProgress(prevProgress => {
        if (prevProgress < 100) {
          return prevProgress + 10
        } else {
          clearInterval(timer)
          return prevProgress
        }
      })
    }, 200)
    handleFinishCompanyCreate()
  }, [setSizeDialog])

  return (
    <Row className="text-center flex-column align-items-center">
      <Col>
        <img src="/images/isotype.png" width={32} alt="Logo" />
      </Col>
      <Col className="mt-3">
        <h2 className="fw-extrabold">{intl.formatMessage({ id: 'Quase Lá...' })}</h2>
      </Col>
      <Col xs={12} md={6} className="mt-3">
        <p className="body1">
          {intl.formatMessage({ id: 'Estamos salvando todas as suas configurações e dentro de instantes tudo estará funcionando...' })}
        </p>
      </Col>
      <Col xs={12} md={4} className='mt-3 mb-3'>
        <ProgressBar progress={progress} />
      </Col>
    </Row>
  )
}

Charging.noDisplayCloseButton = true

export default Charging

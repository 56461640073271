import { ArrowsCounterClockwise, CopySimple } from '@phosphor-icons/react'
import { useCallback, useContext, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'

import copy from 'clipboard-copy'
import permissions from 'Constants/permissions.constants'
import { Button, Col, Row } from 'DesignSystem'
import LoadingDots from 'DesignSystem/LoadingDots'
import beautify from 'json-beautify'

import { addAlert } from 'Store/Actions/alerts.action'

import { ConnectionFormContext } from 'Components/ConnectionForm/ConnectionForm.context'

import useCheckPermission from 'Hooks/API/useCheckPermission.hook'

import { MicroData } from 'Helpers/MicroData.helper'

import { AttributeS } from './DetailConnection.styled'

function DetailsConnection () {
  const dispatch = useDispatch()
  const intl = useIntl()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)

  const { company_id } = useParams<any>()
  const { data: account, isLoading } = useContext(ConnectionFormContext)

  const companiesToVerify = useMemo(() => {
    const companies = []

    if (company_id) {
      companies.push(company_id)
    } else if (account && account.companies) {
      companies.push(...account.companies)
    }

    return companies
  }, [account, company_id])

  const { allProps: { propsSomeTrue } = {} } = useCheckPermission([permissions.MANAGE_TOKENS], companiesToVerify)

  const here = useMemo(() => {
    searchParams.set('renew', 'true')
    const url = process.env.REACT_APP_FRONT_URL + location.pathname + '?' + searchParams.toString()
    return encodeURIComponent(url)
  }, [location])

  const renewTokenUrl = useMemo(() => {
    if (account) {
      const apiUrl = process.env.REACT_APP_API_URL
      const TKMUrl = process.env.REACT_APP_TKM_URL
      const platform = account.platform === 'amazon-ads' ? 'amazon' : account.platform
      const tokenLocal = localStorage.getItem('odash-app:user')
      const urlUri = encodeURIComponent(`${TKMUrl}/account/${account.id}/login/${platform}`)

      return `${apiUrl}token-manager?token=${tokenLocal}&url=${urlUri}?next=${here}`
    }
  }, [account, here])

  const handleCopy = useCallback(() => {
    if (account) {
      const contentParsed = account.token
        ? beautify(account.token,
        // @ts-ignore
          null, 2, 100)
        : intl.formatMessage({ id: 'Sem conteúdo' })
      copy(contentParsed)
      dispatch(
        addAlert({
          type: 'success',
          title: intl.formatMessage({ id: 'Token copiado!' })
        })
      )
    }
  }, [account, dispatch, intl])

  const handleCopyID = () => {
    if (account) {
      copy(account.id)
      dispatch(
        addAlert({
          type: 'success',
          title: intl.formatMessage({ id: 'ID da conexão copiado!' })
        })
      )
    }
  }

  return (
    !isLoading && account ? (
      <div>
        <Row className='justify-content-between align-items-center mt-3'>
          <Col xs={6} lg={7}>
            <AttributeS>
              {intl.formatMessage({ id: 'ID:' })} {account.id}
            </AttributeS>
          </Col>
          <Col className='d-grid' xs={12} md={4} xxl={3}>
            <Button
            color='primary'
            onClick={handleCopyID}
            data-component-type={MicroData.INFO_FROM_MODEL}
            >
              <CopySimple size={24} />
              {intl.formatMessage({ id: 'Copiar ID' })}
            </Button>
          </Col>
        </Row>
        <Row className='justify-content-between align-items-center mt-3'>
          <Col xs={6} lg={7}>
            <AttributeS>
              {intl.formatMessage({ id: 'Token:' })} {JSON.stringify(account.token)}
            </AttributeS>
          </Col>
          <Col className='d-grid' xs={12} md={4} xxl={3}>
            <Button
              color='primary'
              onClick={handleCopy}
              data-component-type={MicroData.INFO_FROM_MODEL}
            >
              <CopySimple size={24} />{intl.formatMessage({ id: 'Copiar token' })}
            </Button>
          </Col>
        </Row>
        <Row className='justify-content-between align-items-center mt-3'>
          <Col xs={6} lg={7}>
            <AttributeS>
              {intl.formatMessage({ id: 'Emitido' })}: {account.token_timestamp}
            </AttributeS>
          </Col>
          <Col className='d-grid' xs={12} md={4} xxl={3}>
          <Button
            color='primary'
            href={renewTokenUrl}
            data-component-type={MicroData.INFO_FROM_MODEL}
            {...propsSomeTrue}
          >
            <ArrowsCounterClockwise size={24} />
            {intl.formatMessage({ id: 'Renovar token' })}
          </Button>
          </Col>
        </Row>
        <Row className='justify-content-between align-items-center mt-3'>
          <Col xs={6} lg={7}>
            <AttributeS>
              {intl.formatMessage({ id: 'ID externo' })}: {account.external_id}
            </AttributeS>
          </Col>
        </Row>
        <Row className='justify-content-between align-items-center mt-3'>
          <Col xs={6} lg={7}>
            <AttributeS>
            {intl.formatMessage({ id: 'Token Status:' })} {intl.formatMessage({ id: `token_status_${account.token_status ?? 'unknown'}` })}
            </AttributeS>
          </Col>
        </Row>
      </div>
    ) : (
      <div className='my-5'>
        <LoadingDots />
      </div>
    )
  )
}

export default DetailsConnection

import { ArrowRight, CaretDown, CaretUp, CheckCircle, XCircle } from '@phosphor-icons/react'
import { useEffect, useState } from 'react'
import ContentLoader from 'react-content-loader'
import { useIntl } from 'react-intl'
import ReactTooltip from 'react-tooltip'

import type { DataStartSignPlanContentManagerI } from 'ActionsContentManager/SignPlan/Hooks/useStartSignPlan.hook'
import { Col } from 'DesignSystem'

import type { IconSVGPropsI } from 'Components/IconSVG'
import IconSVG from 'Components/IconSVG'
import Image from 'Components/Image'

import usePlans from 'Hooks/API/usePlans.hook'
import useUser from 'Hooks/API/useUser.hook'
import type { AuxStepContentManagerI } from 'Hooks/useContentManager.hook'
import useIntlShort from 'Hooks/useIntlShort.hook'

import { ButtonWithHover, ButtonWithoutHover, ChoosePaymentPeriod, IconCloseLocalS, PaymentPeriodButton, PlanGradeItem, PlanGradeItems, PlanGradeToggler, PlanHeader, PlanItem, PlanVantages, PlansListRoot, PlansRoot, QuestionsCardRoot, TagPlans } from './PlansModal.styled'

function QuestionsCard () {
  const intl = useIntlShort()
  return (
    <QuestionsCardRoot href='https://calendly.com/lucas-vernieri' target='_blank'>
      <div>
        <Image
          src="/images/logos/isotype.png"
          alt="Logo"
          style={{ maxWidth: '44px' }}
          />
      </div>
      <div className='ms-2'>
        <h3 className='m-0 fw-bold'>{intl('Alguma dúvida?')}</h3>
        <p className='d-flex text-nowrap align-items-center text-decoration-underline' >{intl('Fale conosco')} <ArrowRight weight='bold' /> </p>
      </div>
    </QuestionsCardRoot>
  )
}

type PlansModalPropsI = AuxStepContentManagerI<DataStartSignPlanContentManagerI>

interface PlanLocalI {
  id: string,
  name: string,
  priceID?: string,
  price?: string,
  priceMessageAfter?: string,
  priceWithoutDiscount?: string,
  unlimited_ad_accounts: boolean,
  access_to_crms: string[],
  unlimited_users: boolean,
  database: boolean,
  conectors_title: string,
  support_items_title: string,
  conectors: string[],
  total_workspaces: any,
  total_connections: string,
  isActive?: boolean,
  isUpgrade?: boolean,
  isDowngrade?: boolean,
  isCustom?: boolean,
  href?: string,
  target?: string,
  tierInitialQuotaInvestiment?: number,
  currency?: string
}

function PlansModal (props: PlansModalPropsI) {
  const intl = useIntlShort()
  const intlUtil = useIntl()
  const { data: user, isLoading: isLoadingUser } = useUser()
  const { close, dataContentManager } = props
  const [planOpened, setplanOpened] = useState(0)
  const [paymentPeriod, setPaymentPeriod] = useState('year')
  const [isLoadingSignPlan, setIsLoadingSignPlan] = useState(false)
  const { isLoading, data: plansBack, actions: { signAPlan, checkDowngrade } } = usePlans()

  const handleSignAPlan = async (plan?: PlanLocalI) => {
    if (!plan || !plan.priceID) return

    setIsLoadingSignPlan(true)
    const response = await checkDowngrade(plan.id)

    if (response.isDowngrade && dataContentManager && dataContentManager.onStartDowngrade) {
      dataContentManager.onStartDowngrade({
        planID: plan.id,
        isDowngrade: true,
        downgradeInfo: response
      })
      close()
    } else {
      await signAPlan(plan.priceID)
    }
  }

  const plans : Array<PlanLocalI> = plansBack?.map((plan, index) => {
    const price = plan.prices.find(price => price.interval === paymentPeriod)

    const prevPlan = index > 0 ? plansBack[index - 1] : null
    const previousConnectors: any[] = []
    if (prevPlan) {
      previousConnectors.push(...prevPlan.connectionsEnabled)
    }

    const newConnectors = plan.connectionsEnabled.filter(
      (connector) => !previousConnectors.includes(connector)
    )

    const priceToFormat = paymentPeriod === 'year' && price?.amount ? price?.amount / 12 : price?.amount

    const totalPrice = intlUtil.formatNumber(price?.amount || 0, {
      style: 'currency',
      currency: price?.currency,
      minimumFractionDigits: 2
    })

    return ({
      id: plan.id,
      name: plan.name,
      priceID: price?.id,
      price: intlUtil.formatNumber(priceToFormat || 0, {
        style: 'currency',
        currency: price?.currency,
        minimumFractionDigits: 2
      }),
      priceMessageAfter: paymentPeriod === 'month' ? intl('pagando mensalmente') : `${intl('por mês, pago anualmente.')} <br/> ${intl('Total de')} ${totalPrice}`,
      priceWithoutDiscount: paymentPeriod === 'year' ? intl('De valor por apenas', {
        price: intlUtil.formatNumber(price?.amountWithoutDiscount || 0, {
          style: 'currency',
          currency: price?.currency,
          minimumFractionDigits: 2
        })
      }) : undefined,
      unlimited_ad_accounts: true,
      access_to_crms: [],
      currency: price?.currency,
      unlimited_users: true,
      database: true,
      conectors_title: prevPlan ? `${intl('Conectores do plano')} ${prevPlan.name} +` : '',
      support_items_title: prevPlan ? `${intl('Opções de suporte do plano')} ${prevPlan.name} +` : '',
      conectors: newConnectors,
      total_workspaces: plan.countWorkspacesEnabled,
      total_connections: intl('Ilimitadas'),
      isActive: plan.isActive,
      isUpgrade: plan.isUpgrade,
      isDowngrade: plan.isDowngrade,
      tierInitialQuotaInvestiment: plan.investmentQuota ? plan.investmentQuota.tiers.filter(tier => tier.unit_amount === 0)[0].up_to : 0
    })
  })

  if (isLoading && plans.length === 0) {
    plans.push(...Array.from({ length: 4 }, () => ({
      id: 'loading',
      name: '',
      priceID: '',
      price: '',
      unlimited_ad_accounts: true,
      unlimited_users: true,
      database: true,
      access_to_crms: [],
      conectors_title: '',
      conectors: [],
      total_workspaces: '',
      totakl_connections: '',
      isActive: false,
      isUpgrade: false,
      isDowngrade: false
    })) as any)
  }

  if (plans.length > 0 && !isLoading) {
    plans.push({
      id: 'custom',
      name: intl('Personalizado'),
      unlimited_ad_accounts: true,
      unlimited_users: true,
      access_to_crms: ['hubspot', 'pipedrive', 'rdstation', 'salesforce'],
      database: true,
      conectors_title: intl('Acesso a todos os conectores disponíveis.'),
      support_items_title: plans && plans[plans.length - 1] ? `${intl('Opções de suporte do plano')} ${plans[plans.length - 1].name} +` : '',
      conectors: [],
      total_workspaces: intl('Fale com nosso comercial'),
      total_connections: intl('Ilimitadas'),
      isActive: false,
      isUpgrade: false,
      isDowngrade: false,
      isCustom: true,
      href: intl('Link plan custom'),
      target: '_blank'
    })
  }

  const supportItems = [
    [
      intl('Central de Ajuda'),
      intl('Atendimento via Ticket')
    ],
    [
      intl('Atendimento por E-mail')
    ],
    [
      intl('Customização de setups de dashboards'),
      intl('Apoio estratégico de especialistas')
    ],
    [
      intl('Account Manager'),
      intl('Reuniões mensais de acordo com sua necessidade'),
      intl('Prioridade no atendimento de suporte')
    ]
  ]

  useEffect(() => {
    setTimeout(() => {
      ReactTooltip.rebuild()
    }, 500)
  }, [isLoading])

  const getButtonPlanSelectText = (plan: any) => {
    if (plan.isCustom) return 'Entrar em contato'
    if (plan.isActive) return 'Plano atual'
    if (plan.isUpgrade) return 'Fazer upgrade'
    if (plan.isDowngrade) return 'Fazer downgrade'
    return !isLoadingUser && user?.isNewerUserRaw ? 'Iniciar Avaliação' : 'Assinar Plano'
  }

  const togglePlan = (planKey: number) => planOpened === planKey ? setplanOpened(-1) : setplanOpened(planKey)

  const loaderTitle = <ContentLoader width="150" height="40"><rect x="0" y="0" rx="5" ry="5" width="150" height="40"></rect></ContentLoader>
  const loaderPrice = <ContentLoader width="50" height="20"><rect x="0" y="0" rx="5" ry="5" width="50" height="40"></rect></ContentLoader>

  // get only one discount of price for year of plansBack
  const discountOff = plansBack?.[0]?.prices?.find(p => p.interval === 'year')?.discount || 0

  return (
    <div>
      <ChoosePaymentPeriod>
        <PaymentPeriodButton active={paymentPeriod === 'month'} onClick={() => setPaymentPeriod('month')}>{intl('Pagamento mensal')}</PaymentPeriodButton>
        <PaymentPeriodButton active={paymentPeriod === 'year'} onClick={() => setPaymentPeriod('year')}>{intl('Pagamento anual')} {discountOff ? <TagPlans>{discountOff}% off</TagPlans> : null} </PaymentPeriodButton>
      </ChoosePaymentPeriod>
      <PlansRoot>
        <IconCloseLocalS onClick={() => close()} />
        <Col className='p-0' xs={12} lg={3}>
          <QuestionsCard />
          <PlanVantages>
            <li>{intl('Contas de anúncio ilimitadas')}</li>
            <li style={{ height: '120px' }}>{intl('Configuração ilimitada dos conectores')}</li>
            <li>{intl('Integração facilitada com CRMs para obter insights ainda mais poderosos.')}</li>
            <li>{intl('Limite de valor investido em campanhas por workspace ao mês')}</li>
            <li>{intl('Quantidade de workspaces disponíveis para criação')}</li>
            <li>{intl('Banco de dados próprio e modelagem de dados nativa')}</li>
            <li>{intl('Usuários e dashboards ilimitados')}</li>
            <li style={{ height: '150px' }}>{intl('Suporte')}</li>
          </PlanVantages>
        </Col>
        <Col className='p-0'>
          <PlansListRoot>
            {plans.map((plan, key) => (
              <PlanItem key={key} active={plan.isActive}>
                <PlanHeader active={plan.isActive}>
                  <div>
                    <h3>{isLoading ? loaderTitle : plan.name}</h3>
                    <div className='d-flex align-items-center'>
                      <h2 className='fw-bold mb-0 me-1'>{isLoading ? loaderPrice : plan.price}</h2>
                      {discountOff && plan.price && paymentPeriod === 'year' ? <TagPlans>{discountOff}% off</TagPlans> : null}
                    </div>
                    <h4 dangerouslySetInnerHTML={{ __html: !isLoading && plan.priceMessageAfter ? plan.priceMessageAfter : '' }}></h4>
                  </div>
                  <ButtonWithHover isLoading={isLoadingSignPlan} style={{ whiteSpace: 'nowrap' }} color={'primary'} disabled={plan.isActive} {...(plan.href ? { href: plan.href, target: plan.target } : { onClick: () => handleSignAPlan(plan) })}>
                    {isLoading ? '...' : intl(getButtonPlanSelectText(plan))}
                  </ButtonWithHover>
                  <ButtonWithoutHover isLoading={isLoadingSignPlan} style={{ whiteSpace: 'nowrap' }} color={plan.isActive ? 'primary' : 'gray'} disabled={plan.isActive} {...(plan.href ? { href: plan.href, target: plan.target } : { onClick: () => handleSignAPlan(plan) })}>
                    {isLoading ? '...' : intl(getButtonPlanSelectText(plan))}
                  </ButtonWithoutHover>
                </PlanHeader>
                <PlanGradeItems active={planOpened === key}>
                  <PlanGradeItem active={plan.isActive}>
                    <div className='d-block d-md-none text-start'>
                      {intl('Contas de anúncio ilimitadas')}
                    </div>
                    {isLoading ? loaderTitle : <CheckCircle weight='fill' size="24" color='#7EE497' />}
                  </PlanGradeItem>
                  <PlanGradeItem className='flex-wrap px-4' active={plan.isActive} style={{ height: '120px' }}>
                    <div className='d-block d-md-none text-start w-100'>
                      {intl('Conectores disponíveis no plano')}:
                    </div>
                    <div>
                      {isLoading ? loaderTitle : plan.conectors_title}
                    </div>
                    {isLoading ? loaderTitle : (plan.conectors.map((conector, key) => (
                      <Col className='text-start d-flex justify-content-start ' xs={1} lg={2} key={key}>
                        <IconSVG icon={conector as IconSVGPropsI['icon']} data-for='tip-global-permission' data-tip={intl(conector)} />
                      </Col>
                    )))}
                  </PlanGradeItem>
                  <PlanGradeItem active={plan.isActive}>
                    <div className='d-block d-md-none text-start'>
                      {intl('Integração facilitada com CRMs para obter insights ainda mais poderosos.')}
                    </div>
                    {plan.access_to_crms.length > 0
                      ? (
                      <>
                        <div className='text-center'>
                          {intl('Acesso aos CRMs')}
                        </div>
                        <div className='d-flex justify-content-between'>
                          {(plan.access_to_crms.map((crm, key) => (
                            <Col className='text-start d-flex justify-content-start ' xs={1} lg={2} key={key}>
                              <IconSVG icon={crm as IconSVGPropsI['icon']} data-for='tip-global-permission' data-tip={intl(crm)} />
                            </Col>
                          )))}
                        </div>
                      </>
                        )
                      : isLoading ? loaderTitle : (
                          <div className='d-flex align-items-center'>
                            <XCircle weight='fill' size="24" color='#ec7788' /> {intl('Indisponível')}
                          </div>
                      )}
                  </PlanGradeItem>
                  <PlanGradeItem active={plan.isActive}>
                    <div>
                      {isLoading ? loaderTitle : (plan.tierInitialQuotaInvestiment ? intlUtil.formatNumber(plan.tierInitialQuotaInvestiment || 0, {
                        style: 'currency',
                        currency: plan.currency,
                        minimumFractionDigits: 2
                      }) : intl('Fale com nosso comercial'))}
                    </div>
                  </PlanGradeItem>
                  <PlanGradeItem active={plan.isActive}>
                    <div className='d-block d-md-none text-start'>
                      {intl('Quantidade de workspaces que podem ser criados')}
                    </div>
                    {isLoading ? loaderTitle : `${plan.total_workspaces} Workspaces`}
                  </PlanGradeItem>
                  <PlanGradeItem active={plan.isActive}>
                    <div className='d-block d-md-none text-start'>
                      {intl('Banco de dados próprio e modelagem de dados nativa')}
                    </div>
                    {isLoading ? loaderTitle : <CheckCircle weight='fill' size="24" color='#7EE497' />}
                  </PlanGradeItem>
                  <PlanGradeItem active={plan.isActive}>
                    <div className='d-block d-md-none text-start'>
                      {intl('Usuários e dashboards ilimitados')}
                    </div>
                    {isLoading ? loaderTitle : <CheckCircle weight='fill' size="24" color='#7EE497' />}
                  </PlanGradeItem>
                  <PlanGradeItem active={plan.isActive}>
                    <div>
                      {plan.support_items_title}
                    </div>
                    <ul className='support-items'>
                    {supportItems[key].map((supItem, key) => (
                      <li key={key} className='d-flex text-start mt-2'>
                        <Col xs={2}>
                          <CheckCircle weight='fill' size="24" color='#7EE497' />
                        </Col>
                        <Col>
                        <small>
                          {supItem}
                        </small>
                        </Col>
                      </li>
                    ))}
                    </ul>
                  </PlanGradeItem>
                </PlanGradeItems>
                <PlanGradeToggler onClick={() => togglePlan(key)}>
                  {planOpened === key ? (
                      <div>
                      Hide the features <CaretUp size='1.5rem' />
                      </div>
                  ) : (
                      <div>
                      See the features <CaretDown size='1.5rem' />
                      </div>
                  )}
                </PlanGradeToggler>
              </PlanItem>
            ))}
          </PlansListRoot>
        </Col>
      </PlansRoot>
      {/* {intlUtil.locale.indexOf('pt') > -1 && (
        <div className='text-white mt-3'>
          <small>
            <span className='text-error'>*</span>Caso o limite do plano seja excedido, haverá cobrança adicional na renovação do seu plano (assinatura mensal) ou no fechamento do mês (assinatura anual). Plano basico: <b> taxa de R$0,03 a cada R$1,00 excedido</b>. Plano Intermediário: <b>Taxa de R$0,05 por cada R$1,00 excedido</b>. Plano Avançado: <b>Taxa de R$3,34 por cada R$1,00 excedido</b>.
          </small>
        </div>
      )} */}
    </div>
  )
}

PlansModal.dialogProps = {
  style: {
    backgroundColor: 'transparent'
  }
}

PlansModal.noDisplayCloseButton = true

export default PlansModal

import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'

import { Input } from 'DesignSystem'

import { addAlert } from 'Store/Actions/alerts.action'
// import { checkCompanyNameAvailability } from 'Store/Actions/Company'

import type { DataCompanyCreateContentManagerI } from 'Components/FirstSetupFlow'

import type { AuxStepContentManagerI } from 'Hooks/useContentManager.hook'
import useDebounce from 'Hooks/useDebounce.hook'

const ERROR_UNAVAIABLE =
  'Este nome já está em uso, por favor escolha outra opção.'
const ERROR_COUNT_NAME = 'O nome deve ter apenas 30 caracteres.'

type NameCompanyCreateDialogPropsI =
  AuxStepContentManagerI<DataCompanyCreateContentManagerI>;

interface NameWorkspaceFieldPropsI {
  isEdit?: boolean;
}

const NameWorkspaceField = (props: NameCompanyCreateDialogPropsI & NameWorkspaceFieldPropsI) => {
  const { setData, dataContentManager, isEdit = false } = props
  const intl = useIntl()
  const dispatch = useDispatch()
  const { name } = dataContentManager
  const [isAvailable, setIsAvailable] = useState<boolean | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [companyNameLocal, setCompanyNameLocal] = useState(name || '')
  const companyNameDebounce = useDebounce(companyNameLocal || '', 500)

  // const canNext = (value : boolean) => {
  //   if (setEnableNext) {
  //     setEnableNext(value)
  //   }
  // }

  const checkAvailabilityName = async (companyName: string) => {
    if (companyName === undefined || companyName === '') return

    setIsLoading(true)

    try {
      // const response = await checkCompanyNameAvailability(companyName.trim())
      const response = { status: 'success' }
      const companyNameIsValid = (isEdit ? true : response.status === 'success') && companyName.length <= 30
      const companyNameIsEmpty = companyName.trim() === ''
      const companyNameIsExceeded = companyName.length > 30

      setIsAvailable(
        companyNameIsEmpty
          ? null
          : companyNameIsExceeded
            ? false
            : companyNameIsValid
      )
      setErrorMessage(companyNameIsExceeded ? ERROR_COUNT_NAME : ERROR_UNAVAIABLE)
      setData({
        name: companyName,
        isValidName: companyNameIsEmpty ? false : companyNameIsValid
      })
    } catch (e: any) {
      setIsAvailable(false)
      setErrorMessage(ERROR_UNAVAIABLE)
      dispatch(addAlert({ title: e.message, type: 'error' }))
      setData({
        name: companyName,
        isValidName: false
      })
    } finally {
      setIsLoading(false)
    }
  }

  const setCompanyName = (companyName: string) => {
    setErrorMessage('')
    setIsAvailable(null)
    setCompanyNameLocal(companyName)
  }

  // useEffect(() => {
  //   canNext(isAvailable === true)
  // }, [isAvailable])

  useEffect(() => {
    checkAvailabilityName(companyNameDebounce)
  }, [companyNameDebounce])

  return (
    <div>
      <label className="fw-bold">
        {intl.formatMessage({ id: 'Nome:' })}{' '}
        <span className="text-error">*</span>:
      </label>
      <Input
        error={isAvailable === false}
        className="mt-2"
        placeholder={intl.formatMessage({ id: 'Nome do Workspace' })}
        value={companyNameLocal}
        onChange={({ target }: any) => setCompanyName(target.value)}
        isValid={isAvailable}
        isLoading={isLoading}
      />
      {errorMessage && !isAvailable && (
        <small className='text-error'>{errorMessage}</small>
      )
      }
    </div>
  )
}

export default NameWorkspaceField
